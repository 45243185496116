import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';

import withInViewEvent from '../withInViewEvent';

import { LANDING_SECTIONS, PUBLIC_ASSETS_LINK } from '../utils/seoConstants';

import Button from '../Button';

import styles from './FindYourHome.module.scss';

const FindYourHome = ({ showImage = true }) => {
  return (
    <section className={styles['find-your-home']}>
      {showImage && (
        <img
          src={`${PUBLIC_ASSETS_LINK}livingroom.svg`}
          alt={trans('find-your-home.title')}
          loading="lazy"
        />
      )}
      <h2 className={styles['find-your-home__title']}>
        {trans('find-your-home.title')}
      </h2>
      <Button
        className={styles['find-your-home__cta']}
        onClick={() => window.scrollTo(0, 0)}
      >
        {trans('find-your-home.cta')}
      </Button>
    </section>
  );
};

FindYourHome.propTypes = {
  showImage: PropTypes.bool
};

export default withInViewEvent(LANDING_SECTIONS.findYourHome)(FindYourHome);
