import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const DeviceContext = createContext();

export const DeviceProvider = ({
  children,
  deviceType = '',
  deviceOS = ''
}) => {
  return (
    <DeviceContext.Provider value={{ deviceType, deviceOS }}>
      {children}
    </DeviceContext.Provider>
  );
};

DeviceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  deviceType: PropTypes.string,
  deviceOS: PropTypes.string
};

export const DeviceConsumer = DeviceContext.Consumer;

export const useDeviceContext = () => useContext(DeviceContext);

export default { DeviceProvider, DeviceConsumer, useDeviceContext };
