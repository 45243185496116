/* global dataLayer */

/* eslint-disable import/first */
import { useEffect } from 'react';
import NoSSR from 'react-no-ssr';
import Metrics from '@spotahome/soyuz-tracking';

import {
  ThemeProvider,
  CookiesProvider,
  ToastContainer,
  GoogleOneTap,
  SpottyCat,
  UserInfoProvider,
  generateFontPreload,
  TenantTypeProvider,
  AppBanner,
  CitiesProvider,
  Footer,
  DeviceProvider
} from '@spotahome/ui-library';
import { useCoreWebVitals } from '@spotahome/ui-library/src/hooks/useCoreWebVitals';
import usePageLoadTime from '@spotahome/ui-library/src/hooks/usePageLoadTime';

import { useSoyuzData, useSoyuzBrandConfig } from '@spotahome/soyuz/client';

import getGqlClient from '@spotahome/landings-common/src/graphql/client';

import { sendTenantActionEvent } from '@spotahome/landings-common/src/graphql/mutations/tenantActions';

import LazyLoginSignUpModal from '@spotahome/landings-common/src/components/LoginSignupModal';
import OurGuarantee from '@spotahome/ui-library/src/tenant/components/OurGuarantee';
import FAQSection from '@spotahome/ui-library/src/tenant/components/FAQSection';

import PropertyTypeSection from '@spotahome/landings-common/src/components/PropertyTypeSection';
import CitiesShowcase from '@spotahome/landings-common/src/components/CitiesShowcase';
import TestimonialsCarousel from '@spotahome/landings-common/src/components/TestimonialsCarousel/TestimonialsCarousel';
import OptInWrapper from '@spotahome/landings-common/src/components/OptInWrapper';

import {
  getTenantFaq,
  getTenantStructuredFaq
} from '@spotahome/landings-common/src/utils/faq';

import FindYourHome from '@spotahome/ui-library/src/tenant/components/FindYourHome';

import {
  CustomDimensionsManager,
  constants
} from '@spotahome/ecommerce-tracking';

import '@spotahome/landings-common/src/scss/init.scss';

import Hero from './components/Hero';
import GuaranteeSection from './components/GuaranteeSection';
import LandlordSection from './components/LandlordSection';

const getTenantStructuredFaqScript = trans => ({
  type: 'application/ld+json',
  innerHTML: JSON.stringify(getTenantStructuredFaq(trans))
});

const getPreloadImageLink = imageUrl => {
  if (!imageUrl) return null;

  return {
    rel: 'preload',
    as: 'image',
    href: imageUrl
  };
};

const SMALL_DEVICE_IMAGES = [
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-mobile.svg'
];

const IMAGES_TO_PRELOAD = {
  smartphone: SMALL_DEVICE_IMAGES,
  tablet: SMALL_DEVICE_IMAGES,
  desktop: [
    'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-desktop.svg'
  ]
};

const EXPERIMENT_IMAGES = [
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-mobile-white.svg'
];

const IMAGES_TO_PRELOAD_EXPERIMENT = {
  smartphone: EXPERIMENT_IMAGES,
  tablet: EXPERIMENT_IMAGES,
  desktop: EXPERIMENT_IMAGES
};

const LandingPage = () => {
  const { [LandingPage.NAME]: requestedData } = useSoyuzData();
  const { isPartner } = useSoyuzBrandConfig();

  const { userId, initialCookie, isSafari, deviceType } = requestedData;

  useCoreWebVitals();
  usePageLoadTime();

  useEffect(() => {
    Metrics.actions.configureClient(async ({ actionId, payload }) => {
      try {
        await sendTenantActionEvent(getGqlClient())({
          actionId,
          payload
        });
      } catch (e) {
        console.log('error sending sendTenantActionEvent:', e);
      }
    });
  }, []);

  useEffect(() => {
    if (userId && typeof dataLayer !== 'undefined') {
      dataLayer.push({ userId });
    }
  }, [userId]);
  return (
    <CookiesProvider initialCookie={initialCookie}>
      <ThemeProvider flags={{ newButtons: true }}>
        <UserInfoProvider>
          <DeviceProvider deviceType={deviceType}>
            <CitiesProvider>
              {!isPartner ? <SpottyCat /> : null}
              <AppBanner source="home" deviceType={deviceType} />
              <ToastContainer />
              {!userId && <GoogleOneTap />}
              <TenantTypeProvider>
                <div data-browser={isSafari ? 'safari' : 'other'}>
                  <Hero deviceType={deviceType} />
                  {!isPartner && (
                    <>
                      <GuaranteeSection />
                      <TestimonialsCarousel />
                      <OurGuarantee />
                      <PropertyTypeSection deviceType={deviceType} isRedesign />
                      <LandlordSection />
                      <CitiesShowcase deviceType={deviceType} isRedesign />
                      <FAQSection questions={getTenantFaq()} isRedesign />
                      <FindYourHome />
                      <Footer theme="beige" />
                      <OptInWrapper />
                    </>
                  )}
                  <NoSSR>
                    <LazyLoginSignUpModal />
                  </NoSSR>
                </div>
              </TenantTypeProvider>
            </CitiesProvider>
          </DeviceProvider>
        </UserInfoProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
};

LandingPage.requestAllData = async req => {
  const imagesToPreloadConfig =
    req.getExperiment('MobileTabletHomepageAboveTheFold2').get('behaviour') ===
    'variant'
      ? IMAGES_TO_PRELOAD_EXPERIMENT
      : IMAGES_TO_PRELOAD;

  const imagesToPreload = (
    imagesToPreloadConfig[req.device.type] || imagesToPreloadConfig.desktop
  ).map(i => getPreloadImageLink(i));
  const fontPreload = generateFontPreload(req.brandConfig);
  try {
    const { locale, userId } = req;
    const cdManager = CustomDimensionsManager();
    cdManager.setCommonCustomDimensions({
      userId,
      locale,
      pageType: constants.TRACKING_PAGE_TYPES.HOMEPAGE
    });

    const isSafari =
      req.device.info && req.device.info.client
        ? req.device.info.client.name.toLowerCase().includes('safari')
        : false;

    return {
      headers: {
        scripts: [
          { innerHTML: cdManager.flush() },
          getTenantStructuredFaqScript(req.trans)
        ],
        links: [...fontPreload, ...imagesToPreload]
      },
      data: {
        userId: req.userId,
        deviceType: req.device.type,
        initialCookie: req.headers.cookie,
        isSafari
      }
    };
  } catch (err) {
    req.logger.error('Landing page error', { err });
  }

  return {
    headers: {
      links: [...fontPreload, ...imagesToPreload]
    },
    data: {
      userId: null,
      topHomecards: null
    }
  };
};

LandingPage.NAME = 'LandingPage';

export default LandingPage;
