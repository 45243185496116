import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import { trans, getResourcePath } from '@spotahome/soyuz/client';

import './PropertyTypeSectionCard.scss';

const PropertyTypeSectionCard = ({ type, isRedesign = false }) => {
  const PropertyTypeSectionCardName = trans(
    `property.type.section.card.${type}`
  );
  const PropertyTypeSectionCardDescription = trans(
    `property.type.section.card.description.${type}`
  );

  const baseImagePath = isRedesign
    ? '/images/home/property-type-redesign'
    : '/images/home/property-type';

  const classes = classNames('property-type-section-card', {
    'property-type-section-card--redesign': isRedesign
  });

  const imageClasses = classNames('property-type-section-card__image', {
    'property-type-section-card__image--redesign': isRedesign
  });

  const infoClasses = classNames('property-type-section-card__info', {
    'property-type-section-card__info--redesign': isRedesign
  });

  const titleClasses = classNames('property-type-section-card__info-title', {
    'property-type-section-card__info-title--redesign': isRedesign
  });

  const titleLabelClasses = classNames({
    'property-type-section-card__info-title-label--redesign': isRedesign
  });

  return (
    <div className={classes}>
      <LazyLoad height={200} offset={[150, 150]} once>
        <figure className={imageClasses}>
          <img
            src={getResourcePath(`${baseImagePath}/${type}.jpg`)}
            alt={PropertyTypeSectionCardName}
            loading="lazy"
          />
        </figure>
      </LazyLoad>

      <div className={infoClasses}>
        <h3 className={titleClasses}>
          <span className={titleLabelClasses}>
            {PropertyTypeSectionCardName}
          </span>
        </h3>
        <span className="property-type-section-card__info-description">
          {PropertyTypeSectionCardDescription}
        </span>
      </div>
    </div>
  );
};

PropertyTypeSectionCard.propTypes = {
  type: PropTypes.string.isRequired,
  isRedesign: PropTypes.bool
};

export default PropertyTypeSectionCard;
